import GET_INTEGRATION_RESYNC_DATA from '@/graphql/GetIntegrationResyncData.gql';
import FAILED_SUBSCRIBERS_BETWEEN_DATES from '@/graphql/FailedSubscribersBetweenDates.gql';
import ADD_FAILED_RESYNC_ROW from '@/graphql/AddFailedResyncRow.gql';
import failedIntegrationResyncStorage from '@/services/integrations/resync/storage';

const RESYNC_STATUS_TYPES = {
  IN_PROGRESS: 'in_progress',
  DONE: 'done',
};

class FailedIntegrationResyncService {
  constructor(databaseId, apollo) {
    this.databaseId = databaseId;
    this.$apollo = apollo;
  }

  isInProgress(logItem) {
    return logItem.status === RESYNC_STATUS_TYPES.IN_PROGRESS;
  }

  isInDone(logItem) {
    return logItem.status === RESYNC_STATUS_TYPES.DONE;
  }

  isDeleted(rowItem) {
    return rowItem.integrationType === 'deleted';
  }

  hasError(rowItem) {
    return rowItem.integrationStatus === 'error';
  }

  getIntegrationType() {
    return failedIntegrationResyncStorage.getIntegrationType();
  }

  prepareForResync(rowItem) {
    const { campaignId, integrationId, errorCount, integrationType } = rowItem;
    failedIntegrationResyncStorage.addToStore({
      databaseId: this.databaseId,
      campaignId,
      integrationId,
      errorCount,
      integrationType,
    });
  }

  getErrorCount() {
    return failedIntegrationResyncStorage.getErrorCount();
  }

  getResyncDataFromSession() {
    return failedIntegrationResyncStorage.getExisting();
  }

  updateSessionWithDate({ date }) {
    const existing = failedIntegrationResyncStorage.getExisting();

    if (!existing) {
      throw Error(
        '[integration-resync]: not existing data in session storage unable to update with period',
      );
    }

    Object.assign(existing, date);
    failedIntegrationResyncStorage.update(existing);
  }

  updateSessionWithErrorCount(errorCount) {
    const existing = failedIntegrationResyncStorage.getExisting();

    if (!existing) {
      throw Error(
        '[integration-resync]: not existing data in session storage unable to update error count',
      );
    }

    existing.errorCount = errorCount;

    failedIntegrationResyncStorage.update(existing);
  }

  getIntegrationProperties(integrationSettings) {
    // handle new integration flow
    if (integrationSettings.hasOwnProperty('bindings')) {
      return {
        newIntegrationId: integrationSettings.id,
        newIntegrationType: integrationSettings.type,
        newIntegrationName: integrationSettings.name,
      };
    }

    return {
      newIntegrationId: integrationSettings._id,
      newIntegrationType: integrationSettings.type,
      newIntegrationName: integrationSettings.data.name,
    };
  }

  async integrationSetupDone(newIntegration) {
    const {
      campaignId,
      integrationId: oldIntegrationId,
      interval,
      errorCount,
    } = failedIntegrationResyncStorage.getExisting();

    failedIntegrationResyncStorage.deleteResync();
    failedIntegrationResyncStorage.updateResyncPollTimestamp();

    const { newIntegrationId, newIntegrationType, newIntegrationName } =
      this.getIntegrationProperties(newIntegration);

    await this.$apollo.mutate({
      mutation: ADD_FAILED_RESYNC_ROW,
      variables: {
        newIntegrationId,
        newIntegrationType,
        newIntegrationName,
        oldIntegrationId,
        campaignId,
        interval,
        errorCount,
      },
    });
  }

  async getIntegrationResyncData() {
    const {
      data: { integrationResyncData },
    } = await this.$apollo.query({
      query: GET_INTEGRATION_RESYNC_DATA,
    });

    return integrationResyncData;
  }

  async fetchLeadsCount() {
    const sessionData = failedIntegrationResyncStorage.getExisting();
    if (!sessionData) return;

    const {
      data: {
        failedSubscribersBetweenDates: { remainingLeads },
      },
    } = await this.$apollo.query({
      query: FAILED_SUBSCRIBERS_BETWEEN_DATES,
      variables: sessionData,
    });

    return remainingLeads;
  }
}

export default FailedIntegrationResyncService;
