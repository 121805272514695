<template lang="pug">
.subscribers-wrapper(
  :style="{ width: !hasAnySubscribers && !loadingSubscribers ? '' : 'width: calc(100vw - 11.5rem)' }"
)
  template(v-if="preventSubscriberDataStorage")
    .brand-box.brand-box-gray.text-center.pt-5.pb-5.flex-wrap.justify-content-center
      .pt-3
        img(src="@/assets/admin/img/monk-wave.png" style="width: 13.25rem; height: 11.875rem")
      .w-100.pt-4.font-size-1--5.font-weight-bold
        span {{ $t('subscribersPage.notCollectingLeads') }}
  template(v-else-if="!hasAnySubscribers && !loadingSubscribers")
    empty-state(
      :text1="$t('emptyState.leads.text1')"
      :text2="$t('emptyState.leads.text2')"
      :imageUrl="require('@/assets/empty-state/leads.png')"
      split-ratio="half"
      @show="leadsEmptyStateShowed"
    )
      template(#underText)
        om-link(primary withIconRight @click="handleLeadsEmptyStateCTAClick") {{ $t('emptyState.leads.link') }}
  template(v-else)
    bulk-operation(:items.sync="selectedSubscribers" @delete="deleteSubscribers")
      template(slot="selectedStr")
        .mr-3 {{ `${$tc('subscriberSelected', selectedIds.length, { count: selectedIds.length })}` }}
    .row.pb-5
      .col-12.col-xl-9.col-xxxl-7.mt-6
        .row
          div(:class="filterColClasses")
            om-select#leads-filter-domains(
              v-model="selectedDomains"
              :options="domains"
              :placeholder="`${$t('select')} domain`"
              searchable
              multiple
              optionText="domain"
              optionKey="_id"
              @input="filterChange"
              size="small"
            )
          .col-4
            om-select#leads-filter-campaigns(
              v-model="selectedCampaigns"
              :options="campaigns"
              :placeholder="`${$t('select')} ${$t('campaigns')}`"
              searchable
              multiple
              optionText="name"
              optionKey="id"
              @input="filterChange"
              size="small"
            )
          .datetime-filter-col(:class="filterColClasses")
            om-date-time#leads-filter-range(
              mode="range"
              :maxDate="new Date()"
              showClear
              size="small"
              @datetime="onDateChange"
            )
          .col-2.d-flex.failed-sync-col(v-if="hasErrors")
            om-checkbox#failed-sync(
              v-model.sync="failedFilter"
              :label="$t('subscribersPage.filters.syncFailed')"
            )
      .col-12.col-xl-3.col-xxxl-5.mt-6.d-flex.justify-content-end
        om-select#leads-filter-columns.mr-3(
          v-model="availableColumns"
          multiple
          :options="customerFields"
          :placeholder="$t('columns')"
          optionText="header"
          hideMultiSelection
          size="small"
          style="min-width: 9rem"
        )
        popper(trigger="click" :options="{ placement: 'bottom' }" ref="exportPopper")
          .popper.brand-popover.d-flex
            om-button.mb-0.mr-2(primary small @click="exportLeads('xlsx')") XLSX
            om-button.mb-0(primary small @click="exportLeads('csv')") CSV
          .popper-content(slot="reference")
            om-button(primary small) {{ $t('export') }}
    .subscriber-container
      .campaign-table.d-flex.flex-wrap
        .brand-campaigns-statistics-table.w-100(
          :class="{ 'brand-table-sticky': needStickyHeader, 'brand-table-sticky-bulk': bulkOperationActive }"
        )
          subscriber-table(
            :subscribers="subscribers"
            :availableColumns="availableColumns.map(({ key }) => key)"
            :subscriberCount="subscriberCount"
            :loadingSubscribers="loadingSubscribers"
            :fields="fields"
            :isSearchResult="isFiltered"
            :sortable="true"
            :selectable="true"
            :pagination="pagination"
            :columnClasses="columnClasses"
            @selectedIdsChange="selectedIds = $event"
            @sortingChange="sorting = $event"
            @paginationChange="paginationChange($event)"
          )
    integration-error-details(@refetchSubscribers="fetchSubscribers")
</template>
<script>
  import { get as _get } from 'lodash-es';
  import { track } from '@/services/xray';
  import moment from 'moment';
  import SubscriberTable from '@/components/SubscriberTable.vue';
  import ALL_SUBSCRIBERS from '@/graphql/AllSubscribers.gql';
  import GET_ACCOUNT_DOMAINS from '@/graphql/GetAccountDomains.gql';
  import CAMPAIGN_LIST from '@/graphql/CampaignList.gql';
  import DELETE_SUBSCRIBERS from '@/graphql/DeleteSubscribers.gql';
  import BulkOperation from '@/components/BulkOperation.vue';
  import IntegrationErrorDetails from '@/components/Modals/IntegrationErrorDetails.vue';
  import EmptyState from '@/components/EmptyState.vue';
  import { mapGetters, mapMutations } from 'vuex';
  import { getBrandedClassString } from '@/components/Elements/Button';

  const _clone = (v) => JSON.parse(JSON.stringify(v));

  export default {
    components: {
      SubscriberTable,
      BulkOperation,
      IntegrationErrorDetails,
      EmptyState,
    },
    props: {
      hasErrors: { type: Boolean, default: false },
    },
    data() {
      return {
        columnClasses: {
          contact: 'brand-campaigns-statistics-table-contact',
          date: 'text-right d-flex justify-content-end brand-campaigns-statistics-table-date',
        },
        selectedIds: [],
        selectedDate: null,
        selectedSyncStatus: null,
        subscribers: [],
        subscriberCount: 0,
        loadingSubscribers: true,
        fields: [],
        sorting: { field: 'date', direction: -1 },
        pagination: { page: 1, limit: 30 },
        domains: [],
        selectedDomains: [],
        campaigns: [],
        selectedCampaigns: [],
        filterDropDown: false,
        availableColumns: [
          { header: this.$t('contact'), key: 'contact' },
          { header: this.$t('syncStatus.title'), key: 'syncStatus' },
          { header: this.$t('campaign'), key: 'campaign' },
          { header: this.$t('domain'), key: 'domain' },
          { header: this.$t('date'), key: 'date' },
          { header: this.$t('customFields'), key: 'customFields' },
        ],
        customerFields: [
          { header: this.$t('contact'), key: 'contact' },
          { header: this.$t('syncStatus.title'), key: 'syncStatus' },
          { header: this.$t('campaign'), key: 'campaign' },
          { header: this.$t('domain'), key: 'domain' },
          { header: this.$t('date'), key: 'date' },
          { header: this.$t('customFields'), key: 'customFields' },
        ],
        showExportPopover: false,
        exportLoading: false,
        needStickyHeader: false,
        hasAnySubscribers: null,
      };
    },
    computed: {
      ...mapGetters(['preventSubscriberDataStorage', 'isSuperAdmin', 'getExportEmailAddress']),
      routeCampaignId() {
        return this.$route.params.campaignId || this.$route.query.campaignId;
      },
      isFiltered() {
        return (
          this.selectedDate !== null ||
          this.selectedDomains.length !== 0 ||
          this.selectedCampaigns.length !== 0
        );
      },
      selectedSubscribers() {
        return this.subscribers
          .filter((c) => this.selectedIds.indexOf(c._id) > -1)
          .map((c) => {
            return {
              _id: c._id,
            };
          });
      },
      bulkOperationActive() {
        return this.selectedIds.length > 0;
      },
      failedFilter: {
        get() {
          return !!this.selectedSyncStatus;
        },
        set(status) {
          this.selectedSyncStatus = status ? 'failed' : null;
        },
      },
      filterColClasses() {
        return this.hasErrors ? 'col-3' : 'col-4';
      },
    },

    watch: {
      sorting: {
        handler() {
          this.fetchSubscribers();
          this.$store.commit('updateTableSorting', this.sorting);
        },
        deep: true,
      },
      selectedSyncStatus(value) {
        this.selectedSyncStatus = value || null;
        this.updateUrlSynStatus(value);
        this.filterChange();
      },
    },
    mounted() {
      const body = document.querySelector('body');
      body.addEventListener('scroll', this.stickyHeaderHandler);
    },
    beforeDestroy() {
      const body = document.querySelector('body');
      body.removeEventListener('scroll', this.stickyHeaderHandler);
    },
    created() {
      this.fetchDomains();
      this.fetchCampaigns();

      if (this.$route.query) {
        const { syncStatus, dateStart } = this.$route.query;

        if (syncStatus) {
          this.selectedSyncStatus = syncStatus;
        }

        if (dateStart) {
          this.selectedDate = {
            start: moment.unix(dateStart).toDate(),
            end: moment().endOf('day').toDate(),
          };
        }
      }

      if (!this.routeCampaignId) {
        this.fetchSubscribers();
      }
      this.$store.commit('updateTableSorting', this.sorting);
    },
    methods: {
      ...mapMutations(['showAdminLoader']),
      stickyHeaderHandler(e) {
        this.needStickyHeader = e.target.scrollTop > 260;
      },
      paginationChange({ page, limit }) {
        this.pagination = { page, limit };
        this.fetchSubscribers();
        this.$store.commit('updateTablePagination', this.pagination);
      },
      dateFilterChange(v) {
        this.pagination.page = 1;
        this.selectedDate = v;

        if (v === null) {
          const query = { ...this.$route.query };

          delete query.dateStart;

          this.$router.replace({ query });
        }

        this.fetchSubscribers();
      },
      filterChange() {
        this.pagination.page = 1;
        if (!this.selectedCampaigns.includes(this.routeCampaignId)) {
          const resolved = this.$router.resolve({ query: { ...this.$route.query } });
          const { pathname, search } = window.location;
          if (resolved.href !== `${pathname}${search}`) {
            this.$router.replace(resolved.route);
          }
        }

        this.fetchSubscribers();
      },
      fetchSubscribers() {
        const filter = {
          campaignIds: this.selectedCampaigns.map((c) => c.id),
          domainIds: this.selectedDomains.map((d) => d._id),
        };
        if (this.selectedDate) {
          filter.dateStart = this.selectedDate.start;
          filter.dateEnd = this.selectedDate.end;
        }
        if (this.selectedSyncStatus) {
          filter.syncStatus = this.selectedSyncStatus;
        }

        this.loadingSubscribers = true;
        this.$apollo
          .query({
            query: ALL_SUBSCRIBERS,
            variables: {
              pagination: this.pagination,
              sorting: this.sorting,
              filter,
            },
          })
          .then(({ data }) => {
            this.loadingSubscribers = false;
            this.showAdminLoader(false);
            const { subscribers, fields, count } = data.allSubscribers;
            this.subscribers = _clone(subscribers);
            if (this.hasAnySubscribers === null) {
              this.hasAnySubscribers = !!this.selectedSyncStatus || !!this.subscribers.length;
            }
            this.subscriberCount = count;
            this.fields = fields;
          });
      },
      fetchDomains() {
        this.$apollo
          .query({
            query: GET_ACCOUNT_DOMAINS,
          })
          .then(({ data: { accountDomains } }) => {
            this.domains = accountDomains;
          });
      },
      fetchCampaigns() {
        this.$apollo
          .query({
            query: CAMPAIGN_LIST,
          })
          .then(({ data }) => {
            this.campaigns = _get(data, 'campaignList');
            const routeCampaignId = this.routeCampaignId;
            if (routeCampaignId) {
              const routeCampaign = this.campaigns.find(
                (c) => c.id.toString() === routeCampaignId.toString(),
              );
              if (routeCampaign) {
                this.selectedCampaigns = [routeCampaign];
              }
              this.fetchSubscribers();
            }
          });
      },
      exportLeads(type) {
        this.$refs.exportPopper.doClose();

        const filter = {
          campaignIds: this.selectedCampaigns.map((c) => c.id),
          domainIds: this.selectedDomains.map((d) => d._id),
        };
        if (this.selectedDate) {
          filter.dateStart = this.selectedDate.start;
          filter.dateEnd = this.selectedDate.end;
        }

        let email = this.getExportEmailAddress;
        // superadmin can send to different email address for testing
        if (this.isSuperAdmin) {
          // eslint-disable-next-line no-alert
          email = window.prompt('Where to send superadmin email?', email);
          if (email == null || email.trim().length < 4) {
            throw new Error('email is empty');
          }
        }

        this.$axios
          .get('export/subscribers', {
            params: {
              sorting: this.sorting,
              filter,
              type,
              email,
            },
          })
          .then(() => {
            this.$notify({
              type: 'success',
              duration: 10000,
              text: this.$t('notifications.leads.exportSuccess', { email }),
            });
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              text: this.$t('notifications.leads.exportError'),
            });
          });
      },

      deleteSubscribers(ids) {
        this.$modal.show('dialog', {
          text: this.$t('confirmationDialog'),
          buttons: [
            {
              title: this.$t('yes'),
              class: getBrandedClassString({ primary: true }, 'mr-3'),
              handler: () => {
                this.$apollo
                  .mutate({
                    mutation: DELETE_SUBSCRIBERS,
                    variables: {
                      ids,
                    },
                  })
                  .then(() => {
                    this.selectedIds = [];
                    this.$bus.$emit('deselectAllTable');
                    this.fetchSubscribers();
                    this.$modal.hide('dialog');
                  });
              },
            },
            {
              title: this.$t('cancel'),
              class: getBrandedClassString({ secondary: true }),
              default: true,
            },
          ],
        });
      },
      onDateChange([start, end]) {
        if (!start || !end) {
          this.selectedDate = null;
          this.dateFilterChange(null);
          return;
        }
        const dates = { start, end };
        this.selectedDate = dates;

        this.dateFilterChange(dates);
      },
      leadsEmptyStateShowed() {
        track('leadsEmptyStateShow');
      },
      handleLeadsEmptyStateCTAClick() {
        track('leadsEmptyStateCTAClicked');
        const url = this.$t('emptyState.leads.linkUrl');
        return window.open(url, '_blank');
      },
      updateUrlSynStatus(status) {
        const url = new URL(window.location.href);

        if (status) {
          url.searchParams.set('syncStatus', status);
        } else {
          url.searchParams.delete('syncStatus');
        }

        window.history.replaceState({ syncStatus: status }, '', url.toString());
      },
    },
  };
</script>

<style lang="sass">
  .brand-campaigns-statistics-table
    min-height: 30.375rem
  .datetime-filter-col
    .input-group,
    .datetime-content,
    .flatpickr-input
      height: 100%
</style>
