<template lang="pug">
.d-flex.w-100
  .row-item-left-section.d-flex.flex-column.justify-content-center.mt-2.mb-2
    .row-item-title.font-weight-500.mb-1 {{ rowItemTitle(rowData) }}
    .row-item-alt-title.font-weight-500.mb-1(v-if="!isDeletedIntegration(rowData)") {{ rowItemSubTitle(rowData) }}
    .row-item-campaign-name {{ rowData.campaignName }}
  .row-item-center-section.d-flex.align-items-center
    .counter.mr-1 {{ errorCount }}
    span {{ $t('failedIntegration.modal.failure') }}
  .row-item-right-section.d-flex.ml-auto.align-items-center.mt-5.mb-5(
    :class="{ 'no-tooltip': !needTooltip(rowData) && !isLogItem }"
  )
    template(v-if="isLogItem")
      .row-item-date-range {{ getLogDateRangeString(logData) }}
      template(v-if="isInProgress(logData)")
        om-button.m-1.custom-button.re-sync {{ $t('failedIntegration.modal.resyncInProgress') }}
      template(v-else-if="isInDone(logData)")
        om-button.m-1.custom-button.done {{ $t('failedIntegration.modal.done') }}
    template(v-else)
      om-button.m-1(
        v-if="isIntegrationWithError(rowData)"
        secondary
        @click="handleIntegration(rowData, 'edit')"
      ) {{ $t('failedIntegration.modal.editIntegration') }}
      om-button.m-1(
        :style="{ 'margin-right': !needTooltip(rowData) ? '28px' : '' }"
        secondary
        @click="handleIntegration(rowData, 'new')"
      ) {{ $t('failedIntegration.modal.addIntegration') }}
      om-tooltip.ml-2(v-if="needTooltip(rowData)" iconSize="1.5rem" color="#505763")
        span {{ tooltipText(rowData) }}
</template>

<script>
  import moment from 'moment';

  export default {
    props: {
      type: { type: String, default: 'rowItem' },
      rowData: Object,
      logData: Object,
      failedIntegrationResyncService: Object,
    },
    computed: {
      isLogItem() {
        return this.type === 'log';
      },
      errorCount() {
        return this.logData?.errorCount || this.rowData.errorCount;
      },
    },
    methods: {
      needTooltip(item) {
        return this.isDeletedIntegration(item) || this.isIntegrationWithError(item);
      },
      isDeletedIntegration(item) {
        return this.failedIntegrationResyncService.isDeleted(item);
      },
      isIntegrationWithError(item) {
        return this.failedIntegrationResyncService.hasError(item);
      },
      isInProgress(item) {
        return this.failedIntegrationResyncService.isInProgress(item);
      },
      isInDone(item) {
        return this.failedIntegrationResyncService.isInDone(item);
      },
      rowItemTitle(item) {
        return this.isDeletedIntegration(item)
          ? this.$t('integrations.deleted.name')
          : `${item.integrationName} (${this.$t(`integrations.${item.integrationType}.name`)})`;
      },
      rowItemSubTitle(item) {
        return this.isIntegrationWithError(item)
          ? this.$t('failedIntegration.modal.integrationError')
          : this.$t('failedIntegration.modal.missingIntegration');
      },
      tooltipText(item) {
        if (this.isDeletedIntegration(item))
          return this.$t('failedIntegration.modal.tooltip.integrationDeleted');

        return this.$t('failedIntegration.modal.tooltip.integrationError');
      },
      handleIntegration(item, type) {
        this.failedIntegrationResyncService.prepareForResync(item);
        this.$emit('integration-resync', {
          isNew: type === 'new',
          totalLeads: item.errorCount,
          campaignId: item.campaignId,
          serviceInstance: this.failedIntegrationResyncService,
        });
      },
      getLogDateRangeString(log) {
        const { from, to } = log.interval;
        if (!from && !to) {
          return this.$t('dateRange.allTime');
        }

        return `${moment(from).format('YYYY-MM-DD')} - ${moment(to).format('YYYY-MM-DD')}`;
      },
    },
  };
</script>
<style lang="sass" scoped>
  .no-tooltip
    padding-right: 30px
  @media screen and (max-width: 991px)
    .row-item-right-section
      flex-direction: column
</style>
