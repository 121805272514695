import { render, staticRenderFns } from "./Audience.vue?vue&type=template&id=2abaa3fc&scoped=true&lang=pug&"
import script from "./Audience.vue?vue&type=script&lang=js&"
export * from "./Audience.vue?vue&type=script&lang=js&"
import style0 from "./Audience.vue?vue&type=style&index=0&id=2abaa3fc&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2abaa3fc",
  null
  
)

export default component.exports